.App-resizer {
  display: flex;
  align-items: flex-start;
  width: 100%;
  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }
  .previewWrapper {
    max-width: 1920px;
    max-height: 1080px;
    width: 1920px;
    background: yellow;
    .App-header {
      display: flex;
      align-items: center;
      justify-content: center;
      aspect-ratio: 16/9;
      width: 100%;
      .imgContainer {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        img {
          width: 100%;
          object-fit: cover;
          aspect-ratio: 16/9;
          object-position: center;
          z-index: 1000;
        }
      }
    }
  }
  .controls{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    .inputs{
        display: flex;
        flex-direction: column;
        gap: 10px;
        .input.download{
            display: flex;
            flex-direction: column;
            gap: 10px;
            button{
                width: 100%;
                padding: 10px;
                border: none;
                background: #000;
                color: #fff;
                font-size: 16px;
                cursor: pointer;
            }
        }
    }
  }
}
