@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;1,400;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oranienbaum&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display+SC&display=swap");

$breakpoint: 1000px;
$spacing: 16px;

* {
  padding: 0;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  background-color: #f5f5f5;
}

.App {
  display: flex;
  width: 100%;
  height: auto;
  padding: 0;
  overflow: hidden;

  .actual {
    display: flex;
    flex-direction: column;
    gap: $spacing;

    @media (min-width: $breakpoint) {
      flex-direction: row;
    }

    .previewWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      aspect-ratio: 9/16;
      max-height: 100vh;
      background: yellow;
      overflow: hidden;
      flex: 1;
    }

    .preview {
      display: flex;
      justify-content: center;
      width: 1080px;
      height: 1920px;
      min-width: 1080px;
      min-height: 1920px;
      transform-origin: 0 0;

      .App-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: black;
        font-size: calc(12px + 2vmin);
        aspect-ratio: 9/16;
        text-align: center;
        position: relative;
        // max-width: 1080px;
        p {
          margin: 10px;
          z-index: 100;
        }
      }
    }

    .conrols {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: flex-start;

      .controls-inner {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: $spacing;
        width: calc(100% - (2 * $spacing));

        .row {
          width: 100%;
          margin-bottom: $spacing;
        }

        h2 {
          display: flex;
          width: 100%;
          padding: 0 0 ($spacing/2) 0;
          margin-top: $spacing;
          margin-bottom: $spacing;
          width: 100%;
          color: #000;
          border-bottom: 1px solid #000;
          font-family: "Cormorant Garamond";
        }

        .form-group {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          margin-bottom: $spacing;

          label {
            width: 100%;
            margin-bottom: ($spacing/4);
            font-weight: 500;
          }

          input {
            width: calc(100% - ($spacing * 2));
            font-size: 16px;
            padding: ($spacing/2);
            border: 1px solid #ccc;
          }

          &.check {
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            background: #eee;
            padding: $spacing/2;
            width: calc(100% - ($spacing * 2));

            label,
            input {
              width: auto;
              line-height: 20px;
              margin: 0;
              padding: 0;
            }

            input {
              margin-right: $spacing;
            }
          }
        }

        .color-picker {
          display: flex;
          flex: 1;
          flex-direction: row;
          align-items: flex-start;
          flex-wrap: wrap;
          gap: $spacing;

          .color-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 5px;

            .color-fenster {
              width: 50px;
              height: 50px;
              border-radius: 5px;
              border: 1px solid #ccc;
            }
          }
        }

        .logo-picker {
          display: flex;
          flex-direction: row;
          gap: $spacing;
          align-items: flex-start;
          flex: 1;
          flex-wrap: wrap;

          .logo-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: $spacing;
          }
        }

        .logo-item img {
          width: 50px;
          height: 50px;
          object-fit: cover;
        }
      }
    }
  }

  button {
    margin-top: $spacing * 2;
    display: flex !important;
    text-align: center !important;
    justify-content: center !important;
    border: 1px solid #000 !important;
    background: none !important;
    font: normal normal 900 11px/11px Arial, Helvetica, sans-serif !important;
    letter-spacing: 0.55px !important;
    text-transform: uppercase !important;
    padding: $spacing ($spacing * 2) !important;
    border-radius: 5px !important;
    text-decoration: none !important;
    cursor: pointer;
  }

  .error {
    font-size: 14px;
    margin-top: 5px;
    padding: 20px;
    text-align: center;
    background: #ff8787;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
  }
}

.App-header * {
  font-family: "Cormorant Garamond";
  font-weight: 300;
}

.react-colorful {
  flex: 1;
}

.last select {
  flex: 1;
  height: 50px;
  border-radius: 5px;
}

.actual {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.top {
  width: 100%;
  flex: 10;
}

.imgHead {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 64px 0 16px 0;
  gap: 16px;
}

.logo {
  width: 15%;
}

.logoText {
  font-size: 40px;
  text-transform: uppercase;
}

.bgimg {
  width: 100%;
  flex: 9;
}

.bgimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text {
  width: 90%;
  margin: 0 auto;
  font-size: 16px;
}

.text .title {
  line-height: 80px;
  font-size: 60px;
  text-transform: uppercase;
  font-weight: normal !important;
  // letter-spacing: 0;
  font-family: "Playfair Display SC", serif;
}

.hline {
  height: 2px;
  background-color: #000;
  width: 90%;
  margin: 10px auto;
}

.text .sub {
  font-size: 52px;
}

.text .sub.author {
  font-style: italic;
}

.custom-picker {
  display: flex;
  width: 100%;
}

.form-group.picker {
  height: 200px;
  width: 100%;
  align-items: center;
}

.rainbow {
  background: linear-gradient(
    90deg,
    #ff0000 0%,
    #ff7f00 14.28%,
    #ffff00 28.57%,
    #00ff00 42.85%,
    #0000ff 57.14%,
    #4b0082 71.42%,
    #8f00ff 85.71%,
    #ff0000 100%
  );
}

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 16px;
  height: 100vh;
  h1 {
    font-size: 40px;
    font-weight: 500;
  }
  .link-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;

    a {
      text-decoration: none;
      color: black;
      font-size: 20px;
      font-weight: 500;
      border-bottom: 1px solid #00000080;
      &:hover {
        color: #00000080;
      }
    }
  }
}
